<template>
  <div>
    <div class="pop-container">
      <div class="pop-conts">
        <div class="box_list_2row box_bd">
          <common-multi-drop-down
              ref="cmpyNmRef"
              v-model="whInfo.cmpyCd"
              class="w100"
              :params="{searchCmpyGbnGrup : [Constant.efs.cmpyGbn.CLIENT_COMPANY]}"
              :url="`${ApiConfig.efsDomain}/efs/drop-down/company`"
              :data-value-field="'cmpyCd'"
              :data-text-field="'cmpyNm'"
              :placeholder="'회사선택'"
              @change="value=>{
                  propsCmpyCd = value
                }"
          >
          </common-multi-drop-down>
          <div class="mgt10">
          <div class="box_flex">
            <h2 class="mgt10">인원 산출 정보 입력</h2>
            <button :disabled="!propsCmpyCd" @click="onClickWhAdd" class="mid_btn orange">신규산출</button>
          </div>
              <div class="box_moc mgt10">
                <table class="moc_table" cellpadding="0" cellspacing="0">
                  <colgroup>
                    <col width="20%" />
                    <col width="*" />
                    <col width="20%" />
                    <col width="*" />
                  </colgroup>
                  <tr>
                    <td class="txt_c bg_g necessary ">
                      예상 적용일
                    </td>
                    <td v-if="whInfo.isNew">
                      <span>
                        <kendo-datepicker
                            ref="YyyymmRef"
                            :value="whInfo.VModelYyyymm"
                            v-model="whInfo.VModelYyyymm"
                            :min="new Date()"
                            :format="'yyyy/MM/dd'"
                            :placeholder="'년/월/일'"
                        ></kendo-datepicker>
                      </span>
                    </td>
                    <td v-else>{{ whInfo.yyyymm }}</td>
                    <td class="txt_c bg_g necessary ">
                      공정기록구분
                    </td>
                    <td v-if="whInfo.isEdit">
                      <common-multi-drop-down
                          ref="prcsDepth2Ref"
                          v-model="whInfo.prcsDepth2"
                          :params="{ searchCmpyCd : propsCmpyCd, depthRange : 2 }"
                          :url="`${ApiConfig.efsDomain}/efs/drop-down/prcs/depth`"
                          :data-value-field="'prcsDepth2'"
                          :data-text-field="'prcsDepth2Nm'"
                          :disabled="!propsCmpyCd"
                          :all-params-validator="true"
                      >
                      </common-multi-drop-down>
                    </td>
                    <td v-else>{{whInfo.prcsDepth2Nm}}</td>
                  </tr>
                  <tr>
                    <td class="txt_c bg_g necessary ">
                      일일출고 예상량
                    </td>
                    <td v-if="whInfo.isEdit">
                      <span class="k-rtl">
                        <kendo-numerictextbox ref="expectOutCntRef"
                                              :format="'###,#'"
                                              :disabled="whInfo.isEdit === false"
                                              v-model.trim="whInfo.expectOutCnt"
                                              class="w90"
                                              :min="0">
                        </kendo-numerictextbox> 건
                      </span>
                    </td>
                    <td v-else>{{whInfo.expectOutCnt ? whInfo.expectOutCnt + '건' : ''}} </td>
                    <td class="txt_c bg_g necessary ">
                      투입 총 시간
                    </td>
                    <td v-if="whInfo.isEdit">
                      <span class="k-rtl">
                        <kendo-numerictextbox ref="putTotTourRef"
                                              :format="'###,#'"
                                              :disabled="whInfo.isEdit === false"
                                              v-model.trim="whInfo.putTotTour"
                                              class="w85"
                                              :max="24"
                                              :min="0">
                        </kendo-numerictextbox> 시간
                      </span>
                    </td>
                    <td v-else>{{whInfo.putTotHour ? whInfo.putTotHour + '시간' : ''}} </td>

                  </tr>
                </table>
              </div>
              <div class="button_area w50">
                <ul>
                  <li>
                    <button v-show="whInfo.isEdit" @click="onClickWhSave" class="large_btn orange">저장</button>
                  </li>
                </ul>
              </div>
            </div>              
            <div>
          <div class="box_flex">
              <h2 class="mgt10">적정인원 계산 이력</h2>
              <button :disabled="!propsCmpyCd" class="mid_btn" @click="onClickExcelDownload"><i class="download_icon"></i>엑셀 다운로드</button>
          </div>
              <kendo-grid
                  ref="whGridRef"
                  class="mgt10"
                  :height="353"
                  :navigatable="false"
                  :reorderable="true"
                  :column-menu="false"
                  :sortable-mode="'multiple'"
                  :pageable-page-sizes="[5, 10, 20, 50, 100, 500, 1000]"
                  :selectable="'row'"
                  :data-source="WhListDataSource"
                  :columns="gridColumns"
                  :noRecords="{template:'<div class=\'gridNoData\' style=\'text-align: center;\'>표시할 데이터가 없습니다.</div>',}"
              >
              </kendo-grid>
            </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import ApiUtil from "@/api/api.util";
import ApiConfig from "@/api/api.config";
import EtnersEfsUtil from '@/common/etners.efs.util'
import { mapGetters } from 'vuex'
import EtnersDateTimeUtil from '@ebmp-fe-common-module/module/src/common/etners.datetime.util'
import EtnersCommonUtil from '@ebmp-fe-common-module/module/src/common/etners.common.util'
import { saveExcel } from '@progress/kendo-vue-excel-export'
import Constant from '@/common/constant'


export default {
  name: 'CalculatorWorker',
  computed : {
    WhListDataSource: function () {
      const vm = this

      if(!vm.propsCmpyCd){
        return []
      }

      const gridDataSource = new kendo.data.DataSource({
        name: 'gridDataSource',
        page: 1,
        pageSize: 10,
        serverPaging: true,
        serverFiltering: true,
        serverSorting: true,
        transport: {
          read: {
            url: `${ApiConfig.efsDomain}/efs/prod/stat/suit`,
            type: 'GET',
            contentType: 'application/json',
            cache: false,
          },
          parameterMap: function (options, operation) {

            if (operation === 'read') {

              let sort = EtnersEfsUtil.convertJSONArrayToJSON('sort', options.sort)

              vm.lastRouteParams = {
                loginUserToken: vm.$store.state.loginStore.lutk,
                skip: options.skip,
                take: options.take,
                page: options.page,
                pageSize: options.pageSize,
                searchCmpyCd : vm.propsCmpyCd,
                ...sort,

              }
              return vm.lastRouteParams
            }

            if (operation !== 'read' && options.models) {
              return { models: kendo.stringify(options.models) }
            }
          },
        },
        schema: {
          type: 'json',
          parse: function (response) {
            let changeResultData = response.resultData
            let rowNumber = (vm.$refs.whGridRef.dataSource.page() - 1)
                * vm.$refs.whGridRef.dataSource.pageSize()
            for (let i = 0; i < changeResultData?.length; i++) {
              let row = changeResultData[i]
              row.rowNumber = response.total - rowNumber++
            }
            response.resultData = changeResultData
            return response
          },
          total: function (response) {
            return response.total
          },
          data: function (response) {
            if (response.resultStatus.messageCode === '2000') {
              return response.resultData
            }else if(response.resultStatus.messageCode === '4154'){
              vm.$store.dispatch('LOGOUT').then(() => {
                kendo.alert(response.resultStatus.messageText).bind('close', function() {
                  window.location.href = `/login`
                })
              })
            }else {
              kendo.alert( response.resultStatus.messageText)
              return ''
            }
          },
        },
        error: function (e) {
          console.log('error event handler', e.errors[0])
        },
      })
      return gridDataSource
    },
    ...mapGetters(['loginUserData'])
  },
  watch : {
    propsCmpyCd : function(newValue, oldValue){
      if(newValue !== '' && !!newValue){
        this.onClickWhAdd()
      }
    }
  },
  methods : {
    onClickWhAdd : function (){
      const vm = this
      /** kendo 컴포넌트 특성상 객체인 value가 변경되어도 data상의 value는 바뀌지만
       * dom에는 값이 그대로 보여지고있기때문에 직접 kendo method로 초기화해줘야함
       **/
      vm.whInfo = {
        isNew : true,
        isEdit : true
      }
      vm.$refs.YyyymmRef?.kendoWidget().value(null)
      vm.$refs.prcsDepth2Ref?.widget().value('')
      vm.$refs.expectOutCntRef?.kendoWidget().value(null)
      vm.$refs.putTotTourRef?.kendoWidget().value(null)
      vm.$refs.whGridRef.kendoWidget().dataSource.read()
    },
    onClickWhSave () {
      const vm = this

      if (vm.validator()) {
        kendo.confirm(`저장 하시겠습니까?`).done(
            function () {
              vm.whSave()
            })
      }
    },
    whSave() {

      const vm = this
      let resultData

      vm.whInfo.cmpyCd = vm.propsCmpyCd
      vm.whInfo.yyyymm = EtnersDateTimeUtil.dateToYYYYMMDD(vm.whInfo.VModelYyyymm)

      if(vm.whInfo.isNew){
        ApiUtil.post(`${ApiConfig.efsDomain}/efs/prod/stat/suit`, vm.whInfo)
        .then(response => {

          if (response.data === undefined || response.data.resultStatus === undefined) {
            kendo.alert('데이터가 정상적으로 처리되지 않았습니다.')
            return false
          }
          if (response.data.resultStatus.messageCode !== '2000') {
            kendo.alert(response.data.resultStatus.messageText)
            return false
          }
          resultData = response.data.resultData
          vm.onClickWhAdd()
        })
      }
    },
    onClickDeleteCalculatorHistory : function (data){
      const vm = this
      kendo.confirm(`삭제 하시겠습니까?`).done(function () {
        vm.deleteCalculatorHistory(data.cmpyCd, data.calcSuitNo)
      })
    },
    deleteCalculatorHistory : function (cmpyCd, calcSuitNo){
      const vm = this
      ApiUtil.delete(`${ApiConfig.efsDomain}/efs/prod/stat/suit/${cmpyCd}/${calcSuitNo}`)
      .then(response => {

        if (response.data === undefined || response.data.resultStatus === undefined) {
          kendo.alert('데이터가 정상적으로 처리되지 않았습니다.')
          return false
        }
        if (response.data.resultStatus.messageCode !== '2000') {
          kendo.alert(response.data.resultStatus.messageText)
          return false
        }
        vm.$refs.whGridRef.kendoWidget().dataSource.read()
        vm.onClickWhAdd()
        kendo.alert("삭제되었습니다.")
      })
    },
    validator(){
      const vm = this
      if(!vm.whInfo.VModelYyyymm){
        kendo.alert("예상적용일을 선택해주세요.").bind('close', function() {
          setTimeout(function () {
            vm.$refs.YyyymmRef.kendoWidget().open()
          }, 10)
        })
        return
      }
      if(!vm.whInfo.prcsDepth2){
        kendo.alert("공정기록구분을 선택해주세요.").bind('close', function() {
          setTimeout(function () {
            vm.$refs.prcsDepth2Ref.widget().focus()
            vm.$refs.prcsDepth2Ref.widget().toggle()
          }, 10)
        })
        return
      }
      if(!vm.whInfo.expectOutCnt){
        kendo.alert("일일출고 예상량을 입력해주세요.").bind('close', function() {
          setTimeout(function () {
            vm.$refs.expectOutCntRef.kendoWidget().focus()
          }, 10)
        })
        return
      }
      if(!vm.whInfo.putTotTour){
        kendo.alert("투입 총 시간을 입력해주세요.").bind('close', function() {
          setTimeout(function () {
            vm.$refs.putTotTourRef.kendoWidget().focus()
          }, 10)
        })
        return
      }
      return true
    },
    onClickExcelDownload: function(e) {

      const vm = this
      vm.lastRouteParams.requestType = 'EXPORT'
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/prod/stat/suit`, vm.lastRouteParams).then(response => {

        if (response.data.resultStatus.messageCode === '2000') {
          let resultData = response.data.resultData
          if(EtnersCommonUtil.isNotEmpty(resultData) && resultData.length > 0) {

            let customGridColumns = vm.gridColumns.reduce((acc, cur) => {
              if(cur.field !== 'rowNumber'){ acc.push({ ...cur, width : '120px' }) }
              return acc;
            }, []);

            saveExcel({
              data: resultData,
              fileName: '적정인원계산이력_' + EtnersDateTimeUtil.dateToYYYYMMDD(new Date()) + '.xlsx',
              columns: customGridColumns
            })
          } else {
            kendo.alert('다운로드 할 자료가 없습니다.')
          }
        } else {
          kendo.alert( response.data.resultStatus.messageText)
          return false
        }
      })
    },
  },
  data () {
    const vm = this
    const headerAttributes = { style: 'text-align: center;', }
    const centerAttributes = { style: 'text-align: center;' }
    const rightAttributes = { style: 'text-align: right;' }
    return {
      Constant,
      ApiConfig : ApiConfig,
      locaPropsCmpyCd : '',
      whInfo : { isEdit : false},
      searchInfo : {},
      propsCmpyCd : '',
      gridColumns: [
        {
          field: "yyyymm",
          title: "예상적용일",
          width: "7%",
          headerAttributes: headerAttributes,
          attributes: centerAttributes,
        },
        {
          field: "expectOutCnt",
          title: "일일출고량",
          width: "6%",
          headerAttributes: headerAttributes,
          attributes: rightAttributes,
          template: function (dataItem) {
            return EtnersCommonUtil.numberWithCommas(dataItem.expectOutCnt)
          }
        },
        {
          field: "putTotHour",
          title: "투입총시간",
          width: "6%",
          headerAttributes: headerAttributes,
          attributes: rightAttributes,
          template: function (dataItem) {
            return EtnersCommonUtil.numberWithCommas(dataItem.putTotHour)
          }
        },
        {
          field: "eaAvg",
          title: "시간당<br /> 누적생산량/인",
          width: "8%",
          headerAttributes: headerAttributes,
          attributes: rightAttributes,
          template: function (dataItem) {
            return `${EtnersCommonUtil.numberWithCommas(dataItem.eaAvg)} 명`
          }
        },
        {
          field: "resultPersnCnt",
          title: "적정인원(명)",
          width: "7%",
          headerAttributes: headerAttributes,
          attributes: { ...rightAttributes,class : 'bg_orange txt_o'  },
          template: function (dataItem) {
            return EtnersCommonUtil.numberWithCommas(dataItem.resultPersnCnt)
          }
        },
        {
          field: "calcDt",
          title: "산출일자",
          width: "10%",
          headerAttributes: headerAttributes,
          attributes: centerAttributes,
        },
        {
          title: '삭제',
          width: '6%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes,

          command: [{
            name: "delete",
            template : `<a class="mid_btn k-grid-delete">삭제</a>`,
            click: function(e) {
              e.preventDefault();

              let tr = kendo.jQuery(e.target).closest("tr");
              let data = this.dataItem(tr);
              vm.onClickDeleteCalculatorHistory(data)
            }
          }]
        },
      ],
    }
  },
}
</script>

<style scoped>

</style>
